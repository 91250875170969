<template lang="pug">
div
  van-field(:label='data.title' :value="data.value" is-link @click="show = true" readonly)
  van-popup(v-model="show" round position="bottom" style='z-index: 10000' get-container="body")
    van-picker(
      :title='data.picker_title', 
      :columns='data.columns',
      @confirm='onConfirm',
      @cancel='onCancel', 
      @click='onConfirm'
      :value-key='data.key'
      :show-toolbar='data.toolbar'
    )
</template>

<script>

export default {
  name: 'NormalPicker',
  props: ['value'],
  data() {
    return {
      data: {
        title: '',
        value: '',
        // columns: [],
        columns: [
          {
            comId: "96",
            route: "jlxd",
            name: '北京嘉利信得'
          },
          {
            comId: "78",
            route: "dpjylm",
            name: '搭配家云联盟'
          }
        ],
        picker_title: '',
        key: 'name',
        toolbar: true
      },
      show: false,
    }
  },
  watch: {
    value(newV) {
      this.init(newV)
    }
  },
  methods: {
    init(v) {
      if(v)
        this.data = v
    },
    
    onConfirm(value) {
      this.data.value = value[this.data.key]
      this.show = false
      this.$emit('onSelect', value)
    },
    onCancel() {
      this.show = false
    },
  },
  mounted() {
    this.init()
  }
}
</script>