<template lang="pug">
div
  van-form(@submit='onSubmit')
    component(v-for="(vf, vfIndex) of fields"
      :key="vfIndex" 
      :is="vf.component ? vf.component : 'van-field'"
      :label="vf.label" 
      v-model="data[vf.key]" 
      :readonly='vf.readonly'
      v-bind="vf.attrs"
      :maxCount=1
      colon
      clearable
      @onSelect='onSelectStore'
    )
    van-row
      van-button.col(type='info' native-type='submit' block round :disabled='commitState') 提交
</template>

<script>
import NormalPicker from './NormalPicker'
export default {
  name: 'RegisterSeller',
  props: ['value'],
  components: {
    [NormalPicker.name]: NormalPicker
  },
  data() {
    return {
      commitState: false,
      data: {
        name: '',
        com_name: '',
        store: {},
        // url: [],
        store_value: null,
      },
      fields: [
        { 
          label: '销售姓名', 
          key: 'name',
        },
        { 
          label: '服务公司', 
          key: 'com_name',
          readonly: true,
         },
        { 
          label: '服务门店', 
          key: 'store',
          component: 'NormalPicker' ,
        }
        // {
        //   label: '上传名片',
        //   key: 'url',
        //   component: 'UploaderField'
        // }
      ],
    }
  },
  methods: {
    // 选择的门店
    onSelectStore(v) {
      this.data.store_value = v
    },
    async onSubmit() {
      // 附件
      if (!this.data.name) {
        this.$toast('姓名不能为空')
        return
      } else if (!this.data.store_value) {
        this.$toast('请选择一个门店')
        return
      }
      // let url = ''
      // if(this.data.url.length > 0) {
      //   url = this.data.url[0].url
      // }
      this.commitState = true
      let result = await this.createDesignerCart()
      if(result) {
        this.$emit('callBack')
      }
      this.commitState = false
    },
    async createDesignerCart() {
      try {
        await this.createSellUser()
        this.$toast('申请成功,请等待管理员审核...')
        return true
      } catch(ex) {
        this.$toast(this.$error(ex))
      }
      return false
    },
    // 创建销售员
    async createSellUser() {
      const params = {
        where: {
          com_id_poi_companys: this.companyInfo.id
        },
        limit: 1
      }

      let data = {
        name: this.data.name,
        user_poi_users: this.userId,
        tel: this.$store.state.userInfo.u_mobile,
        com_id_poi_companys: this.companyInfo.id,
        st_id_poi_company_stores: this.data.store_value.id
      }
      try {
        let orgRes =  await this.$api.GetCompanyOrg(params)
        data.org_poi_company_org = orgRes.data.items[0].id

        let res =  await this.$api.PostSellerUser(data)
        this.$store.commit('setSellerInfo',  res.data)
        let putdata = {
          user_type_poi_user_types: 5
        }
        await this.$api.PutUser(putdata)
        return res.data
      } catch(ex) {
        this.$toast(this.$error(ex))
      }
    },
    init(newValue){
      this.data.com_name = this.companyInfo.full_name || this.companyInfo.com_name
      this.getComStore()
    },
    // 获取门店列表
    async getComStore() {
      let res = await this.$api.GetCompanyStore(this.companyInfo.id)
      this.stores = res.data.items
      this.data.store = {
        title: '服务门店',
        columns: this.stores,
        picker_title: '选择服务门店',
        value: '',
        key: 'st_name',
        toolbar: true,
      }
    },
  },
  watch: {
    value(v) {
      this.init(v)
    }
  },
  computed: {
    companyInfo() {
      return this.$store.state.companyInfo
    },
    userId() {
      return this.$store.state.userInfo.user_poi_users
    },
    sellerId() {
      return this.$store.state.userInfo.seller_poi_sell_users
    },

  },
  mounted() {
    this.init(this.value)
  }
}
</script>