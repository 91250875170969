<template lang="pug">
div
  .user-poster
    .user-profile
      van-row(type="flex" justify="center")
        van-col(:span="24" @click="$router.push({name: 'personedit'})")
          van-image(
            round
            fit="cover"
            width="100px"
            height="100px"
            :src="headIcon"
            )
      van-row(type="flex" justify="center")
        van-col(:span="24" style="text-align:center;")
          | {{$store.state.userInfo.sell_users ?  $store.state.userInfo.sell_users.name : $store.state.userInfo.ui_name }}
  van-cell-group.user-group(v-for="(group, gindex) of powerGroupData" :key="gindex")
    van-cell( v-for="(item, index) of group.items" :key="index" :icon="item.icon" :title="item.title" :is-link="item.isLink" :to="item.to")
  div(v-if='!power')
    van-cell-group
      van-cell(
        v-if='!seller'
        title="申请成为销售员 享受更多特权"
        icon='upgrade'
        @click='show=true')
      van-cell(
        v-else
        title="销售员资格审核中..."
        icon='user-o'
        )
  van-action-sheet(v-model='show', title='升级销售员')
    register-seller(v-if='show' :value='null' @callBack='callBack')

</template>

<script>

import { Row, Col, Icon, Cell } from 'vant'
import RegisterSeller from '../../components/other/RegisterSeller'

export default {
  components: {
    [Row.name]: Row,
    [Col.name]: Col,
    [Icon.name]: Icon,
    [Cell.name]: Cell,
    [RegisterSeller.name]: RegisterSeller
  },
  data () {
    return {
      head: 'https://img.yzcdn.cn/vant/cat.jpeg',
      nickName: '昵称',
      menuGroups: [
        {
          power: true,
          items: [
            {
              power: true,
              icon: 'records',
              title: '我的报价单',
              isLink: true,
              to: {
                name: 'offer'
              }
            },
            {
              power: true,
              icon: 'records',
              title: '我的订单',
              isLink: true,
              to: {
                name: 'order'
              }
            },
            // {
            //   power: true,
            //   icon: 'records',
            //   title: '生产订单跟踪',
            //   isLink: true,
            //   to: {
            //     name: 'eyasorderlist'
            //   }
            // }
          ],
        },
        {
          power: true,
          items: [
            {
              power: true,
              icon: 'cart-o', 
              title:'我的购物车', 
              isLink: true,
              to: {
                name: 'cart'
              }
            },
            {
              power: true,
              icon: 'like-o', 
              title:'我的收藏', 
              isLink: true,
              to: {
                name: 'collect'
              }
            }
          ]
        },
        {
          power: true,
          items: [
            {
              power: true,
              icon: 'records',
              title: '我的客户',
              isLink: true,
              to: {
                name: 'client'
              }
            },
            {
              power: true,
              icon: 'records',
              title: '我的项目',
              isLink: true,
              to: {
                name: 'project'
              }
            }
          ]
        },
        {
          items: [
            {
              icon: 'setting-o',
              title: '设置',
              isLink: true,
              to: {
                name: 'setting'
              }
            }
          ]
        },
      ],
      show: false,
    }
  },
  computed: {
    companyInfo() {
      return this.$store.state.companyInfo
    },
    // 必须登录且已经注册为公司销售员
    seller() {
      return this.$store.state.userInfo.sell_users
    },
    power() {
      return this.$store.getters.isLogined() && this.$store.state.userInfo.sell_users && this.$store.state.userInfo.sell_users.state == "1" 
    },
    powerGroupData() {
       if(this.power) {
        return this.menuGroups
      } else {
        return this.menuGroups.filter(p=> !p.power)
      }
    },
    // 头像图标
    headIcon() {
      const res = this.$store.state.userInfo.ui_head
      if (res && res.indexOf('b_art_img') == -1) {
        if (res.startsWith('files')) {
          const newRes = 'http://cdn.dpjia.com/' + res + '?x-oss-process=image/resize,h_128,w_128,m_pad,limit_0'
          return newRes
        }
        return res + '?x-oss-process=image/resize,h_128,w_128,m_pad,limit_0'
      }
      return ''
    }
  },
  methods: {
    async callBack() {
      this.show = false
      // window.location.reload()
      let res = (await this.$api.GetUserInfo(this.$store.state.companyInfo.id)).data
      res = {
        ...res,
        ...res.info_poi_user_info,
        seller_poi_sell_users: res.sell_users.id,
        ...res.sell_users
      }

      this.$store.commit('setLoginInfo', {
        isLogined: true,
        userInfo: res
      })
    }
  }
}
</script>

<style lang="less">
.user {
  &-poster {
    width: 100%;
    height: 160px;
    display: block;
    padding-top: 50px;
    background-color: #39a9ed;
  }

  &-group {
    margin-bottom: 15px;
  }

  &-links {
    padding: 15px 0;
    font-size: 12px;
    text-align: center;
    background-color: #fff;

    .van-icon {
      display: block;
      font-size: 24px;
    }
  }
}

.user-profile {
  width: 100px;
  margin: 0px auto;
}
</style>
